/// Field styles
///
@mixin ui-form-field--primary-input($sz--padding-top, $sz--padding-x, $sz--padding-bottom, $sz--border-width) {
    // Vars

    // Support

    // Module
    & {
        .ui-form-field--input-container {
            position: relative;
            // this line is only needed to fix a weird safari bug https://app.asana.com/0/137886543980536/1160542619665009
            // input fields in overlays flicker on safari, so we enable the CSS GPU accelerated rendering to circumvent the problem
            -webkit-transform-style: preserve-3d; // stylelint-disable-line
        }

        .ui-form-field--title-label {
            cursor: pointer;
            transition: $trs--common-props;
        }

        .ui-form-field--input {
            display: inline-block;
            width: 100%;
            padding: $sz--padding-top $sz--padding-x $sz--padding-bottom;
            border-width: $sz--border-width;
            border-style: solid;
            border-radius: $sz--border-radius-base;
            line-height: 1.3;
            cursor: text;
            transition: $trs--common-props;

            @include placeholder {
                transition: $trs--common-props;
            }
        }
    }

    // States
    & {
        // Base
        @include ui-form-field--apply-states(()) {
            @include ui-form-field--primary-input-base;
        }

        // Focus
        @include ui-form-field--apply-states(('disabled': false, 'focus': true)) {
            @include ui-form-field--primary-input-focus;
        }

        // Disabled
        @include ui-form-field--apply-states(('disabled': true, 'focus': default)) {
            @include ui-form-field--primary-input-disabled;
        }

        // Error
        @include ui-form-field--apply-states(('error': true, 'focus': default)) {
            @include ui-form-field--primary-input-error;
        }
    }
}

/// Base
///
@mixin ui-form-field--primary-input-base {
    .ui-form-field--input {
        color: $c--label-text;
        background-color: $c--monochrome-white;
        border-color: $c--monochrome-silver;

        @include placeholder {
            color: $c--monochrome-gray;
            opacity: 1;
        }
    }
}

/// Focus
///
@mixin ui-form-field--primary-input-focus {
    .ui-form-field--title-label {
        color: $c--label-text;
    }

    .ui-form-field--input {
        border-color: $c--monochrome-dark-gray;
    }
}

/// Disabled
///
@mixin ui-form-field--primary-input-disabled {
    .ui-form-field--title-label {
        @include is-selectable(false);
        cursor: default;
    }

    .ui-form-field--input {
        @include is-selectable(false);
        color: $c--monochrome-silver;
        background-color: $c--monochrome-paper-white;
        border-color: $c--monochrome-light-gray;
        cursor: default;

        @include placeholder {
            color: transparent;
        }
    }
}

/// Error
///
@mixin ui-form-field--primary-input-error {
    .ui-form-field--input {
        border-color: $c--signal-error;
    }

    .ui-form-field--title-label {
        color: $c--signal-error;
    }
}
