/* stylelint-disable plugin/selector-bem-pattern, max-nesting-depth, declaration-no-important */

.vdp-datepicker {

    // Support
    @mixin selected-day() {
        color: $c--monochrome-white;

        &::before {
            background: $c--brand-secondary;
        }
    }

    input {
        display: inline-block;
        width: 100%;
        padding: $sz--input-padding-top $sz--input-padding-x $sz--input-padding-bottom;
        border-width: $sz--input-border-width;
        border-style: solid;
        border-radius: $sz--border-radius-base;
        line-height: 1.3;
        cursor: text;
        border-color: $c--monochrome-silver;
        transition: $trs--common-props;
        color: $c--label-text;

        @include has-focus {
            border-color: $c--monochrome-dark-gray;
        }
    }

    .vdp-datepicker__calendar {
        z-index: z-index(calendar);
        border-color: $c--monochrome-dark-gray;
        bottom: 100%;
        width: 100%;
        padding: 4px;
        border-radius: $sz--border-radius-base $sz--border-radius-base 0 0;

        &::after {
            content: '';
            position: absolute;
            bottom: -$sz--border-radius-base;
            left: -1px;
            display: block;
            height: $sz--border-radius-base;
            width: calc(100% + 2px);
            background: $c--monochrome-white;
            border: 1px solid $c--monochrome-dark-gray;
            border-bottom: none;
        }

        @include ff--base-regular;

        @include mq('>sm') {
            min-width: 372px;
        }

        @include mq('<=sm') {
            min-width: 100%;
        }

        header span:nth-child(2) {
            color: $c--monochrome-dark-gray;

            @include ff--base-regular;
        }

        header span:nth-child(2),
        .prev,
        .next {
            padding-top: 4px;
            padding-bottom: 4px;

            @include has-focus {
                color: $c--monochrome-dark-gray !important;
                background: $c--brand-secondary-light !important;
            }
        }

        .prev:not(.disabled) {
            &::after {
                border-right-color: $c--monochrome-dark-gray;
            }
        }

        .next:not(.disabled) {
            &::after {
                border-left-color: $c--monochrome-dark-gray;
            }
        }

        .cell:not(:empty) {
            position: relative;

            &.month,
            &.year {
                height: auto;
                padding-top: 2px;
                padding-bottom: 0;

                &:not(.disabled):not(.selected) {
                    color: $c--brand-primary;
                }

                @include has-focus {
                    color: $c--monochrome-dark-gray !important;
                    border-color: transparent !important;
                    background: $c--brand-secondary-light;
                }

                &.selected:not(:hover) {
                    color: $c--monochrome-white;
                    background: $c--brand-secondary;
                }
            }

            &.day-header {
                font-size: $fz--medium;

                @include ff--base-regular;
            }

            &.day {
                margin-bottom: 8px;
                border: none !important;
                background: none !important;

                > time {
                    z-index: 1;
                    position: relative;
                }

                @include has-focus {
                    time {
                        color: $c--monochrome-dark-gray;
                    }

                    &::before {
                        background: $c--brand-secondary-light;
                    }
                }

                &:not(.disabled):not(.selected) {
                    color: $c--brand-primary;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;

                    width: 40px;
                    height: 40px;
                    border-radius: 100%;

                    transform: translate(-50%, -50%);
                }
            }
        }

        .cell.selected {
            @include selected-day;
        }
    }
}
