.overlay-edit {
    // Vars

    // Support

    // Module
    & {
        .overlay-edit--form {
            @include scrollable-overlay-content;
        }

        .overlay-edit--input-field {
            display: flex;
            flex-direction: column;

            & ~ .overlay-edit--input-field {
                margin-top: 16px;
            }
        }

        .overlay-edit--meta {
            margin-top: 16px;
            flex: 1 1 auto;
        }

        .overlay-edit--meta-component {
            margin-bottom: 16px;
        }

        .overlay-edit--data-picker {
            padding: {
                top: 40px;
            }
        }

        .overlay-edit--checkbox {
            padding: {
                top: 40px;
            };
        }
    }

    // Facets

    // States
}
