.ui-accordion {
    // Vars

    // Support

    // Module
    & {
        .ui-accordion--head {
            cursor: pointer;
        }

        .ui-accordion--body {
            overflow: hidden;
            max-height: 0;
            opacity: 0;
            transition: opacity $trs--base;
        }
    }

    // Facets
    & {
        &.ui-accordion__is-open {
            .ui-accordion--body {
                opacity: 1;
            }
        }

        &.ui-accordion__is-animating {
            .ui-accordion--body {
                transition: opacity $trs--base, max-height $trs--base;
            }
        }
    }

    // States
}
