.main-content-wrapper {
    // Vars

    // Support

    // Module
    & {
        .main-content-wrapper--row {
            justify-content: center;
        }

        .main-content-wrapper--col {
            @include main-center-col;
        }
    }

    // Facets
    & {
        &.main-content-wrapper__building-list {
            .main-content-wrapper--col {
                @include main-building-list-content-col;
            }
        }
    }

    // States
}
