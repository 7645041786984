.overlay-delete {
    // Vars

    // Support

    // Module
    & {
        .overlay-delete--form {
            @include scrollable-overlay-content;
        }

        .overlay-delete--input-field {
            width: 100%;
            margin-bottom: 16px;
        }

        .overlay-delete--date-picker {
            padding: {
                top: 60px;
            }
        }
    }

    // Facets

    // States
}
