.intro {
    // Vars
    $big-padding: 24px;
    $small-padding: 16px;

    // Support

    // Module
    & {
        & {
            @include components-spacing;

            @media print {
                display: none;
            }
        }

        .intro--row {
            justify-content: center;
        }

        .intro--col {
            @include main-building-list-content-col;
        }

        .intro--content {
            position: relative;
            overflow: hidden;

            background: rgba($c--brand-accent, 0.25);
            padding: $big-padding ($big-padding / 2);
            border: 1px solid $c--brand-accent;
            border-radius: $sz--border-radius-base;

            @include mq('<=md') {
                flex-direction: column;
                padding: $small-padding ($small-padding / 2);
            }
        }

        .intro--transition-wrapper {
            display: flex;
            align-items: center;

            @include mq('<=sm') {
                flex-direction: column;
            }
        }

        .intro--close-button {
            position: absolute;
            top: 0;
            right: 0;
            padding: $small-padding / 2;
            color: $c--brand-primary;
            cursor: pointer;

            @include has-focus {
                color: $c--brand-primary-dark;
            }

            @include mq('>md') {
                padding: $big-padding / 2;
            }
        }

        .intro--text,
        .intro--figure {
            padding: 0 $small-padding;
            margin: 0 ($small-padding * -0.5);

            @include mq('>md') {
                padding: 0 $big-padding;
                margin: 0 ($big-padding * -0.5);
            }
        }

        .intro--text {
            flex: 1 1 auto;

            @include mq('<=md') {
                margin-top: 16px;
            }

            @include mq('<=sm') {
                width: 100%;
            }
        }

        .intro--link {
            cursor: pointer;
            color: $c--brand-primary;

            @include ff--base-bold;

            @include has-focus {
                color: $c--brand-primary-dark;
            }
        }

        .intro--title,
        .intro--description {
            margin-bottom: 8px;
        }
    }

    // Facets

    // States
}
