/// Form field facet
///
@mixin ui-form-field--filter {
    // Vars
    $sz--input-padding-top: 13px;
    $sz--input-padding-x: 16px;
    $sz--input-padding-bottom: 11px;

    $sz--input-border-width: 1px;

    $fz--bool-label: $fz--small / 1rem * 10px; // Convert to px
    $lh--bool-label: 1.5;
    $sz--bool-box-gutter: 3px;
    $sz--bool-box: ($fz--bool-label + (2 * $sz--bool-box-gutter));
    $sz--bool-box-border: 2px;

    $sz--actions-icon-offset: 0;
    $sz--actions-icon-margin: 0;
    $sz--actions-icon-width: 28px;

    $sz--floating-label-offset: 7px;
    $sz--floating-label-padding-top: $sz--input-padding-top + $sz--floating-label-offset;
    $sz--floating-label-padding-bottom: $sz--input-padding-bottom - $sz--floating-label-offset;

    // Support

    // Fields
    & {
        @include ui-form-field--primary-all;

        &.ui-form-field__input {
            &.ui-form-field__select {
                @include ui-form-field--filter-select;
                @include ui-form-field--has-actions(
                    $sz--icon-margin: $sz--actions-icon-margin,
                    $sz--icon-offset: $sz--actions-icon-offset,
                    $sz--icon-width: $sz--actions-icon-width,
                    $sz--padding-top: $sz--input-padding-top,
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-bottom: $sz--input-padding-bottom
                );
            }
        }
    }
}
