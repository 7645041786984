$shadow-level-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
$shadow-level-2: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
$shadow-level-3: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);
$shadow-level-4: 0 14px 28px 0 rgba(0, 0, 0, 0.25), 0 10px 10px 0 rgba(0, 0, 0, 0.26);
$shadow-level-5: 0 19px 38px 0 rgba(0, 0, 0, 0.3), 0 15px 12px 0 rgba(0, 0, 0, 0.22);

$shadows: (
    'shadow-level-1': $shadow-level-1,
    'shadow-level-2': $shadow-level-2,
    'shadow-level-3': $shadow-level-3,
    'shadow-level-4': $shadow-level-4,
    'shadow-level-5': $shadow-level-5,
);
