.overlay-update-user {
    // Vars

    // Support

    // Module
    & {
        .overlay-update-user--form {
            @include scrollable-overlay-content;
        }

        .overlay-update-user--input-field {
            width: 100%;
            margin-bottom: 16px;
        }
    }

    // Facets

    // States
}
