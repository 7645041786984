.owner-edit-form-fields {
    // Vars
    $padding: 16px;

    // Support

    // Module
    & {
        & {
            @include grid--root((row: 'row', cols: ('col')));
            @include grid--space((row: 'row', cols: 'col', gutter: ($padding * 2, $padding)));

            .owner-edit-form-fields--row {
                @include mq('<=md') {
                    flex-wrap: nowrap;
                }
            }
        }
    }

    // Facets
    & {
        .owner-edit-form-fields--col {
            &.owner-edit-form-fields--col__plz {
                width: 100%;
                max-width: 92px;
            }

            &.owner-edit-form-fields--col__stretched {
                flex: 1 1 auto;
            }
        }
    }

    // States
}
