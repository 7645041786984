////
/// Global z-index registry to keep track of the global layer hierarchy
////

/// A list of names with their corresponding layer hierarchy, used as `z-index` value
///
$layers: (
    content: 0,
    calendar: 11,
    snackbar: 12,
);

/// Returns a z-index stored within the `$layers` map, which stores z-indexes of all central ui elements
/// @param {string} $layer - Key that is searched for
/// @return {number} A z-index number
///
@function z-index($layer) {
    @if (not map-has-key($layers, $layer)) {
        @error 'Layer with name "#{$key}" was not found in the `$layers` map.';
    }

    @return map-get($layers, $layer);
}

