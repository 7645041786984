$ripple-animation-transition-in: transform 250ms ease-out, opacity 100ms ease-out;
$ripple-animation-transition-out: opacity 300ms ease-in;

.ripple {
    &--container {
        pointer-events: none;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        color: inherit;
        border-radius: inherit;
        width: 100%;
        height: 100%;
        overflow: hidden;
        contain: strict;
    }

    &--animation {
        pointer-events: none;
        color: inherit;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background: currentColor;
        opacity: 0;
        overflow: hidden;
        will-change: transform, opacity;

        &__enter {
            transition: none;
        }

        &__in {
            transition: $ripple-animation-transition-in;
        }

        &__out {
            transition: $ripple-animation-transition-out;
        }
    }
}
