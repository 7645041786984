.main-header {
    // Vars

    // Support

    // Module

    & {
        & {
            @media screen {
                border-bottom: 1px solid $c--monochrome-light-gray;
            }
        }

        .main-header--container {
            display: flex;
            align-content: center;
            justify-content: space-between;
            flex-direction: row;
            padding: 24px 0;

            @include mq('>xs') {
                padding: 30px 0;
            }

            @media print {
                padding: 16px 0;
            }
        }

        .main-header--logo-wrapper {
            display: flex;
            align-items: center;
        }

        .main-header--logo-img {
            height: 28px;

            @include mq('>xs') {
                height: 40px;
            }
        }

        .main-header--menu-list {
            display: flex;
            align-items: center;
            justify-items: flex-end;
            flex-direction: row;
            height: 100%;

            @media print {
                display: none;
            }
        }

        .main-header--menu-item {
            padding-left: 21px;

            @include mq('>xs') {
                padding-left: 35px;
            }
        }

        .main-header--menu-link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            color: $c--brand-primary;
            cursor: pointer;

            @include ff--base-bold;

            @include has-focus {
                color: $c--brand-primary-dark;
            }
        }

        .main-header--link-text {
            padding-left: 8px;
            transform: translateY(1px);
            display: none;

            @include mq('>sm') {
                display: inline-block;
            }
        }
    }


    // Facets

    // States
}
