/// Web-static folder
///
$web-static: '../..';

/// BEM options
///
$bem-element-separator: '--';
$bem-modifier-separator: '__';

// Input fields
$sz--input-padding-top: 13px;
$sz--input-padding-x: 16px;
$sz--input-padding-bottom: 11px;

$sz--input-border-width: 1px;
