////
/// Header styles (hX)
////

@mixin type--h1 {
    @include ff--base-bold;
    font-size: 2.2rem;
    line-height: 1.25;

    @include mq('xs') {
        font-size: 2.2rem;
    }
}

@mixin type--h2 {
    @include ff--base-semi-bold;
    font-size: 1.8rem;
    line-height: 1.25;

    @include mq('xs') {
        font-size: 1.8rem;
    }
}

@mixin type--h3 {
    @include ff--base-bold;
    font-size: 1.6rem;
    line-height: 1.25;
}

@mixin type--h4 {
    @include ff--base-regular;
    font-size: 1.2rem;
}

@mixin type--h5 {
    @include ff--base-regular;
    font-size: $fz--medium;
}

@mixin type--h6 {
    @include ff--base-regular;
    font-size: $fz--medium;
}
