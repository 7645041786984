.component-wrapper {
    // Vars

    // Support

    // Module
    & {
        @include components-spacing;
    }

    // Facets

    // States
}
