.overlay-submit {
    // Vars

    // Support

    // Module
    & {
        .overlay-submit--form {
            @include scrollable-overlay-content;
        }

        .overlay-submit--input-field {
            width: 100%;
            margin-bottom: 16px;
        }

        .overlay-submit--input-description {
            margin-bottom: 16px;
        }
    }

    // Facets
    & {
        .overlay-submit--input-field {
            &.overlay-submit--input-field__email {
                margin-bottom: 40px;
            }
        }
    }

    // States
}
