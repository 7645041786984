.button-group {
    // Vars

    // Support

    // Module
    & {
        & {
            @include grid--root((row: 'row', cols: ('col')));
            @include grid--space((row: 'row', cols: 'col', gutter: 16px, mq: '>md'));
            @include grid--space((row: 'row', cols: 'col', gutter: (16px, 0), mq: '<=md'));
        }

        .button-group--row {
            justify-content: flex-end;
        }

        .button-group--button {
            @include grid--span((ratio: 1, mq: '<=md'));

            @include mq('<=md') {
                display: flex;
                flex-direction: column;
            }
        }
    }

    // Facets

    // States
}
