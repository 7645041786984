.cookie-banner {
    // Vars

    // Support

    // Module
    & {
        & {
            position: fixed;
            bottom: 0;
            width: 100%;
            padding: 4px 0;
            background-color: rgba($c--monochrome-dark-gray, 0.8);
            color: $c--monochrome-white;

            @include mq('<=md') {
                padding: 8px 0;
            }
        }

        .cookie-banner--message-col {
            @include grid--span((ratio: 8 / 12));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .cookie-banner--actions-col {
            @include grid--span((ratio: 4 / 12));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));

            display: flex;
            justify-content: flex-end;
        }

        .cookie-banner--row {
            justify-content: space-between;
            align-items: center;
        }
    }

    // Facets

    // States
}
