.skel {
    // Vars

    // Support

    // Module
    & {
        @media screen {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            min-height: 100%;
            overflow: hidden;
        }

        .skel--header {
            @media screen {
                margin-bottom: 40px;
            }
        }

        .skel--main {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 0 auto;
            page-break-after: always;
        }

        .skel--footer {
            margin-top: 40px;
            page-break-before: always;
        }

        //.skel--root {}
    }

    // Facets

    // States
}
