.form-field-messages {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        margin-top: 4px;

        .form-field-messages--message {
            display: block;
            font-size: $fz--xx-small;
            line-height: $lh--x-small;
            font-weight: $fw--semi-bold;

            ~ .form-field-messages--message {
                margin-top: 3px;
            }

            &.form-field-messages--message__info {
                color: $c--signal-info;
            }

            &.form-field-messages--message__error {
                color: $c--signal-error;
            }
        }
    }

    // Facets
    & {
        &.form-field-messages__absolute {
            position: absolute;
            margin-top: 0;
            top: 100%;
        }
    }


    // States
}
