////
/// Content types (p)
////

@mixin type--copy {
    font-size: $fz--medium;
    line-height: $lh--small;
}

@mixin type--copy2 {
    font-size: $fz--small;
    line-height: $lh--small;
}

@mixin type--copy3 {
    font-size: $fz--x-small;
    line-height: $lh--medium;
}
