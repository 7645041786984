.user-data {
    // Vars
    $container-padding: 24px;
    $button-padding: 16px;
    $list-padding: 16px;

    // Support

    // Module
    & {
        .user-data--title {
            margin-bottom: 16px;
        }

        .user-data--container {
            position: relative;
            background: $c--monochrome-paper-white;
            border: 1px solid $c--monochrome-light-gray;
            padding: $container-padding 0;
            border-radius: $sz--border-radius-base;
            overflow: hidden;

            @media print {
                border: none;
                padding: 0;
                background: transparent;
            }
        }

        .user-data--edit-button {
            position: absolute;
            cursor: pointer;
            top: 0;
            right: 0;
            color: $c--brand-primary;
            background: $c--monochrome-paper-white;
            padding: 16px;

            @include has-focus {
                color: $c--brand-primary-dark;
            }

            @media print {
                display: none;
            }
        }

        .user-data--edit-button-content {
            display: flex;
            align-items: center;
        }

        .user-data--edit-button-text {
            margin-left: 8px;
            transform: translate(0, 1px);

            @include ff--base-bold;

            @include mq('<=xs') {
                display: none;
            }
        }

        .user-data--loader-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 48px;
        }

        .user-data--loader {
            color: $c--brand-primary;
        }

        .user-data--input-label:not(:first-child) {
            margin-top: $list-padding;
        }

        .user-data--input {
            display: flex;
            flex-direction: column;
        }

        .user-data--input-value,
        .user-data--input-label {
            padding: 0 ($container-padding * 3) 0 $container-padding;

            @include ff--base-semi-bold;

            @media print {
                padding: 0;
            }
        }

        .user-data--input-value {
            font-size: $fz--big;
        }

        .user-data--input-label {
            color: $c--monochrome-silver;
            font-size: $fz--x-small;
        }
    }

    // Facets

    .user-data--input-value {
        &.user-data--input-value__divider {
            position: relative;
            padding-bottom: $list-padding;
            border-bottom: 1px solid $c--monochrome-light-gray;

            @media print {
                border: none;
            }
        }
    }

    // States
}
