.login-form {
    // Vars

    // Support

    // Module
    & {
        & {
            margin: 40px auto;
            max-width: 576px;
        }

        .login-form--form {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
        }

        .login-form--submit-button {
            display: flex;
            margin-top: 24px;
            justify-content: flex-end;
        }
    }

    // Facets

    // States
}
