/// Font weights used throughout the project
///
$fw--regular: 400;
$fw--semi-bold: 600;
$fw--bold: 700;

/// The project's font families
///
$ff--base: 'Glober';

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Helvetica, Arial;
// stylelint-enable value-keyword-case
