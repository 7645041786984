/// Field styles
///
@mixin ui-form-field--filter-select {
    // Vars

    // Module
    & {
        .ui-form-field--input {
            @include ff--base-bold;
        }

        .ui-form-field--action.ui-form-field--action {
            pointer-events: none;
        }
    }

    // States
    & {
        // Base
        @include ui-form-field--apply-states(()) {
            @include ui-form-field--filter-select-base;
        }

        // Focus
        @include ui-form-field--apply-states(('disabled': false, 'focus': true)) {
            @include ui-form-field--filter-select-focus;
        }

        // Disabled
        @include ui-form-field--apply-states(('disabled': true, 'focus': true)) {
            @include ui-form-field--filter-select-disabled;
        }

        // Empty
        @include ui-form-field--apply-states(('filled': false)) {
            @include ui-form-field--filter-select-empty;
        }

        // Empty and disabled
        @include ui-form-field--apply-states(('filled': false, 'disabled': true)) {
            @include ui-form-field--filter-select-empty-disabled;
        }
    }
}

/// Base
///
@mixin ui-form-field--filter-select-base {
    .ui-form-field--input {
        cursor: pointer;
    }

    .ui-form-field--action {
        color: $c--brand-primary;
    }
}

/// Focus
///
@mixin ui-form-field--filter-select-focus {
    .ui-form-field--action {
        color: $c--brand-primary;
    }
}

/// Disabled
///
@mixin ui-form-field--filter-select-disabled {
    .ui-form-field--action {
        color: $c--monochrome-light-gray;
        cursor: default;
    }
}

/// Empty
///
@mixin ui-form-field--filter-select-empty {
    .ui-form-field--input {
        color: $c--monochrome-light-gray;

        // NOTE: This revert is necessary for IE11 and Firefox
        option, optgroup {
            color: #000000;
        }

        // NOTE: This revert is necessary for IE11
        &::-ms-value {
            color: $c--monochrome-light-gray;
        }
    }
}

/// Empty and disabled
///
@mixin ui-form-field--filter-select-empty-disabled {
    .ui-form-field--input {
        color: transparent;
    }
}
