.overlay-skeleton {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 32px;
            border-radius: $sz--border-radius-base;
            background: $c--monochrome-white;
            width: 100%;

            @include mq('>sm') {
                max-width: 480px;
            }

            @include mq('<=sm') {
                min-height: 100%;
            }

            @include mq('<=md') {
                padding: 32px 16px;
            }
        }

        .overlay-skeleton--header {
            > h1 {
                margin-bottom: 4px;
            }
        }

        .overlay-skeleton--header {
            padding-bottom: 24px;
        }

        .overlay-skeleton--main {
            padding-bottom: 40px;
        }
    }

    // Facets

    // States
}
