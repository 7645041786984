@mixin ui-form-field--glowing() {
    // Vars

    // Module
    & {
        .ui-form-field--input.ui-form-field--input {
            box-shadow: 0 0 6px $c--brand-primary-light;
            background-color: rgba($c--brand-primary-light, 0.05);
        }
    }
}
