.trs-flip-list {
    // Vars

    // Support

    // Module

    // Facets
    &-enter-active, &-leave-active, &-move {
        overflow: hidden;
        transition: transition-props($trs--base-speed $trs--base-fn, opacity, transform);
    }

    // States
    &-enter {
        opacity: 0;
    }
}
