// stylelint-disable plugin/selector-bem-pattern

////
/// Accopanying css to the npm plugin `vue-recaptcha`.
/// It's allowed to hide the badge when a notification is presented to the user informing him about usage of ReCaptcha.
/// @link https://www.npmjs.com/package/vue-recaptcha
/// @link https://developers.google.com/recaptcha/docs/faq
////

.grecaptcha-badge {
    display: none;
}
