.main-footer {
    // Vars
    // Support
    // Module
    & {
        & {
            color: $c--monochrome-black-gray;
            padding: {
                bottom: 40px;
                top: 40px;
            }

            @media screen {
                background-color: $c--monochrome-paper-white;
                border-top: 1px solid $c--monochrome-light-gray;
            }
        }

        .main-footer--bottom-row {
            margin-top: 32px;
            display: flex;
            align-content: center;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .main-footer--contact-link, .main-footer--copy {
            padding-top: 16px;

            @include grid--span((ratio:4/4, mq:'<=lg'));

            @include mq('>lg') {
                padding-top: 0;
                padding-right: 24px;
            }
        }

        .main-footer--contact-col {
            ~ .main-footer--contact-col {
                @include mq('<=lg') {
                    padding-top: 24px;
                }
            }

            @include grid--span((ratio:4/4, mq:'<=lg'));
            @include grid--span((ratio:3/12, mq:'>lg'));

            @media print {
                flex: 1 1 100%;
                padding-top: 16px;
            }
        }
    }


    // Facets
    & {
        .main-footer--contact-link {
            &.main-footer--contact-link__external {
                @media print {
                    display: none;
                }
            }
        }
    }

    // States
}
