.snackbar {
    // Vars

    // Support

    // Module
    & {
        z-index: z-index(snackbar);
        position: fixed;
        bottom: 16px;
        right: 16px;
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0 16px 12px;

        background: $c--brand-secondary;
        color: $c--monochrome-white;
        border-radius: 2px;
        box-shadow: $shadow-level-1;

        .snackbar--icon {
            display: flex;
            align-items: center;
            margin-right: 8px;
        }

        .snackbar--content {
            @include ff--base-regular;
        }

        .snackbar--close-button {
            cursor: pointer;
            padding: 0 16px;
            transform: translate(0, -1px);
        }
    }

    // Facets
    & {
        &.snackbar__error {
            background: $c--signal-error;
        }
    }

    // States
}
