/// Field styles
///
@mixin ui-form-field--primary-bool($fz--label, $lh--label, $sz--box, $sz--box-border) {
    // Vars
    $sz--label-gutter: 8px;

    // Module
    & {
        font-size: $fz--label;
        line-height: 0;

        .ui-form-field--input {
            @include hide-visually;
        }

        .ui-form-field--input-container {
            display: flex;
            flex: 0 0 auto;
        }

        .ui-form-field--title-label {
            cursor: auto;
        }

        .ui-form-field--label-wrap {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            cursor: pointer;
            max-height: $sz--box;
            transition: $trs--common-props;
        }

        .ui-form-field--label {
            padding: 0 0 0 $sz--label-gutter;

            // Center label on the basis of the flex value `align-items: baseline`
            transform: translateY((($fz--label - $sz--box) / 2) - $sz--box-border - 0.5px);

            @include ff--base-semi-bold;
        }

        .ui-form-field--box {
            @include is-selectable(false);

            position: relative;
            display: block;
            flex: 0 0 auto;
            width: $sz--box;
            height: $sz--box;
            transition: $trs--common-props;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: $trs--common-props;
            }

            &::before {
                z-index: 1;
            }

            &::after {
                z-index: 2;
                border-width: $sz--box-border;
                border-style: solid;
            }
        }

        .ui-form-field--box-icon {
            pointer-events: none;
            z-index: 3;
            opacity: 0;
            transition: $trs--common-props;
        }
    }

    // Facets
    & {
        &.ui-form-field__multiline {
            .ui-form-field--label-wrap {
                max-height: none;
            }

            .ui-form-field--label {
                flex: 0 1 auto;
                line-height: $lh--label;
            }
        }
    }

    // States
    & {
        // Base
        @include ui-form-field--apply-states(()) {
            .ui-form-field--title-label {
                color: $c--label-text;
            }

            .ui-form-field--input {
                // Base
                @include ui-form-field--bool-apply-states(()) {
                    @include ui-form-field--primary-bool-base;
                }

                // Hover
                @include ui-form-field--bool-apply-states(('disabled': false, 'hover': true)) {
                    @include ui-form-field--primary-bool-hover;
                }

                // Focus
                @include ui-form-field--bool-apply-states(('disabled': false, 'focus': true)) {
                    @include ui-form-field--primary-bool-focus;
                }

                // Checked
                @include ui-form-field--bool-apply-states(('checked': true, 'hover': default)) {
                    @include ui-form-field--primary-bool-checked;
                }

                @include ui-form-field--bool-apply-states(('checked': true, 'hover': true)) {
                    @include ui-form-field--primary-bool-checked-hover;
                }

                // Disabled
                @include ui-form-field--bool-apply-states(('disabled': true)) {
                    @include ui-form-field--primary-bool-disabled;
                }

                // Disabled and checked
                @include ui-form-field--bool-apply-states(('disabled': true, 'checked': true)) {
                    @include ui-form-field--primary-bool-disabled-checked;
                }
            }
        }

        // Error
        @include ui-form-field--apply-states(('error': true)) {
            .ui-form-field--title-label,
            .ui-form-field--label {
                color: $c--signal-error;
            }

            .ui-form-field--input {
                // Base
                @include ui-form-field--bool-apply-states(()) {
                    @include ui-form-field--primary-bool-error;
                }

                // Hover
                @include ui-form-field--bool-apply-states(('disabled': false, 'hover': true)) {
                    @include ui-form-field--primary-bool-error;
                }

                // Focus
                @include ui-form-field--bool-apply-states(('disabled': false, 'focus': true)) {
                    @include ui-form-field--primary-bool-error;
                }

                // Checked
                @include ui-form-field--bool-apply-states(('checked': true, 'hover': default)) {
                    @include ui-form-field--primary-bool-error-checked;
                }

                // Disabled
                @include ui-form-field--bool-apply-states(('disabled': true)) {
                    @include ui-form-field--primary-bool-disabled;
                }

                // Disabled and checked
                @include ui-form-field--bool-apply-states(('disabled': true, 'checked': true)) {
                    @include ui-form-field--primary-bool-disabled-checked;
                }
            }
        }
    }
}

/// Base
///
@mixin ui-form-field--primary-bool-base {
    .ui-form-field--box::before {
        opacity: 0;
    }

    .ui-form-field--box {
        background-color: $c--monochrome-white;
    }

    .ui-form-field--box::after {
        border-color: $c--monochrome-silver;
    }

    .ui-form-field--box-icon {
        color: $c--monochrome-dark-gray;
    }
}

/// Hover
///
@mixin ui-form-field--primary-bool-hover {
    .ui-form-field--box-icon {
        opacity: 0.25;
    }

    .ui-form-field--box {
        background-color: $c--monochrome-white;
    }

    .ui-form-field--box::after {
        border-color: $c--monochrome-dark-gray;
    }

    .ui-form-field--box-icon {
        color: $c--monochrome-dark-gray;
    }
}

/// Focus
///
@mixin ui-form-field--primary-bool-focus {
    .ui-form-field--box::before {
        opacity: 1;
    }

    .ui-form-field--box::after {
        border-color: $c--monochrome-black-gray;
    }
}

/// Checked
///
@mixin ui-form-field--primary-bool-checked {
    .ui-form-field--box-icon {
        opacity: 1;
        color: $c--monochrome-white;
    }

    .ui-form-field--box {
        background-color: $c--brand-secondary;
    }

    .ui-form-field--box::after {
        border-color: $c--brand-secondary;
    }
}

/// Checked Hover
///
@mixin ui-form-field--primary-bool-checked-hover {
    $background-color: darken($c--brand-secondary, 10%);

    .ui-form-field--box-icon {
        opacity: 1;
    }

    .ui-form-field--box {
        background-color: $background-color;
    }

    .ui-form-field--box::after {
        border-color: $background-color;
    }
}

/// Error
///
@mixin ui-form-field--primary-bool-error {
    .ui-form-field--box::after {
        border-color: $c--signal-error;
    }
}

/// Error
///
@mixin ui-form-field--primary-bool-error-checked {
    .ui-form-field--box {
        background-color: $c--signal-error;
    }

    .ui-form-field--box::after {
        border-color: $c--signal-error;
    }
}

/// Disabled
///
@mixin ui-form-field--primary-bool-disabled {
    cursor: default;

    .ui-form-field--label {
        cursor: auto;
    }

    .ui-form-field--box {
        background-color: $c--monochrome-light-gray;
    }

    .ui-form-field--box::after {
        border-color: $c--monochrome-light-gray;
    }

    .ui-form-field--box-icon {
        color: $c--monochrome-paper-white;
    }
}

/// Disabled and checked
///
@mixin ui-form-field--primary-bool-disabled-checked {
    .ui-form-field--box-icon {
        color: $c--monochrome-white;
    }

    .ui-form-field--box.ui-form-field--box {
        background-color: $c--monochrome-light-gray;
    }

    .ui-form-field--box.ui-form-field--box::after {
        border-color: $c--monochrome-light-gray;
    }
}
