.address-list {
    // Vars

    // Support
    @mixin add-button-focus {
        background: $c--monochrome-cardboard-gray;
        border-color: $c--monochrome-gray;
    }

    // Module
    & {
        .address-list--header {
            display: flex;
            margin-bottom: 16px;

            @include mq('>md') {
                align-items: center;
            }

            @include mq('<=md') {
                flex-direction: column;
            }
        }

        .address-list--dropdown {
            position: relative;
            display: flex;
            align-items: center;

            @include mq('>md') {
                margin-left: auto;
            }

            @include mq('<=md') {
                margin-top: 16px;
            }
        }

        .address-list--dropdown-description {
            display: inline-block;
            margin-left: 4px;
        }

        .address-list--item {
            @media print {
                page-break-inside: avoid;
            }

            ~ .address-list--item {
                margin-top: 12px;
            }
        }

        .address-list--loader-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 128px;
        }

        .address-list--loader {
            color: $c--brand-primary;
        }

        .address-list--add-button {
            text-align: left;
            width: 100%;
            padding: 24px 16px;
            color: currentColor;
            cursor: pointer;

            @include mq('<=md') {
                padding: 12px;
            }


            @include has-focus {
                color: $c--brand-primary-dark;
            }
        }

        .address-list--add-button-icon {
            border: 2px solid currentColor;
            border-radius: 100%;
        }

        .address-list--add-button-wrap {
            display: flex;
            align-items: center;
        }

        .address-list--add-button-text {
            margin-left: 12px;
            transform: translate(0, 1px);

            @include ff--base-bold;

            @include mq('>md') {
                margin-left: 16px;
            }
        }

        .address-list--buttons {
            margin-top: 40px;

            @media print {
                display: none;
            }
        }

        .address-list--select {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            padding: 0 8px;
        }

        .address-list--dropdown {
            @media print {
                display: none;
            }
        }

        .address-list--dropdown-value {
            margin: 0 4px;

            @include ff--base-bold;
        }

        .address-list--dropdown-icon {
            color: $c--brand-primary;
            transform: translate(0, -1px);
        }
    }

    // Facets
    & {
        .address-list--item {
            &.address-list--item__add-button {
                display: flex;
                color: $c--monochrome-silver;
                background: $c--monochrome-paper-white;
                border: 1px solid $c--monochrome-light-gray;
                border-radius: $sz--border-radius-base;
                transition: transition-props($trs--base, color, border-color, background-color, opacity, transform);

                @include has-focus {
                    @include add-button-focus;
                }

                &:focus-within {
                    @include add-button-focus;
                }

                @media print {
                    display: none;
                }
            }
        }
    }


    // States
}
