/// Monochrome special decoration colors
///
$c--monochrome-white: #ffffff;
$c--monochrome-black: #000000;
$c--monochrome-black-gray: #333333;
$c--monochrome-dark-gray: #404040;
$c--monochrome-silver: #999999;
$c--monochrome-cardboard-gray: #f0f0f0;
$c--monochrome-gray: #bbbbbb;
$c--monochrome-medium-gray: #dddddd;
$c--monochrome-light-gray: #e2e2e1;
$c--monochrome-paper-white: #fafafa;

$monochromes: (
    'white': $c--monochrome-white,
    'black': $c--monochrome-black,
    'black-gray': $c--monochrome-black-gray,
    'cardboard-gray': $c--monochrome-cardboard-gray,
    'dark-gray': $c--monochrome-dark-gray,
    'silver': $c--monochrome-silver,
    'gray': $c--monochrome-gray,
    'medium-gray': $c--monochrome-medium-gray,
    'light-gray': $c--monochrome-light-gray,
    'paper-white': $c--monochrome-paper-white,
);

/// Brand specific colors
///
$c--brand-primary: #50af2d;
$c--brand-primary-light: #7cc361;
$c--brand-primary-dark: #3c8322;
$c--brand-secondary: #0096dc;
$c--brand-secondary-light: #f2fafd;
$c--brand-accent: #ffd700;

$brands: (
    'primary': $c--brand-primary,
    'primary-light': $c--brand-primary-light,
    'primary-dark': $c--brand-primary-dark,
    'secondary': $c--brand-secondary,
    'secondary-light': $c--brand-secondary-light,
    'accent': $c--brand-accent,
);

/// Single purpose colors
///
$c--label-text: $c--monochrome-black-gray;
$c--label-link: $c--brand-primary;
$c--label-selection: null;

$labels: (
    'text': $c--label-text,
    'link': $c--label-link,
    'selection': $c--label-selection,
);

/// Signal colors
///
$c--signal-info: #00b37d;
$c--signal-error: #e1114e;
$c--signal-warning: #ffff00;
$c--signal-success: #00b37d;

$signals: (
    'info': $c--signal-info,
    'error': $c--signal-error,
    'warning': $c--signal-warning,
    'success': $c--signal-success,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    brand: $brands,
    label: $labels,
    signal: $signals,
);
