/// Primary
///
@mixin btn--primary {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--primary-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--primary-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': true, 'active': true)) {
            @include btn--primary-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true)) {
            @include btn--primary-disabled;
        }
    }
}

/// Base
///
@mixin btn--primary-base {
    color: $c--monochrome-white;
    background-color: $c--brand-primary;
    border: $sz--border-width-base solid transparent;

    @include ff--base-bold;
}

/// Focus
///
@mixin btn--primary-focus {
    background-color: $c--brand-primary-dark;
}

/// Active
///
@mixin btn--primary-active {
    background-color: $c--brand-primary-dark;
}

/// Disabled
///
@mixin btn--primary-disabled {
    color: $c--monochrome-white;
    background-color: $c--monochrome-medium-gray;
}
