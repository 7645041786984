.richtext {
    // Vars

    // Support

    // Module
    & {
        margin-bottom: 40px;

        h1, h2, h3, h4, h5, p {
            margin-bottom: 8px;
        }

        h1, h2, ul, ol {
            &:not(:first-child) {
                margin-top: 32px;
            }
        }

        ul, ol {
            margin-bottom: 32px;
        }

        a {
            color: $c--brand-primary;
            text-decoration: underline;

            @include ff--base-bold;

            @include has-focus {
                color: $c--brand-primary-dark;
            }
        }
    }

    // Facets

    // States
}
