.overlay-share {
    // Vars
    $padding: 16px;

    // Support

    // Module
    & {
        & {
            @include grid--root((row: 'row', cols: ('col')));
            @include grid--space((row: 'row', cols: 'col', gutter: ($padding, $padding)));

            @include scrollable-overlay-content;
        }

        .overlay-share--input {
            transition: transition-props($trs--base, box-shadow);
        }

        .overlay-share--row {
            @include mq('>sm') {
                flex-wrap: nowrap;
                align-items: flex-end;
            }

            @include mq('<=sm') {
                flex-direction: column;
            }
        }

        .overlay-share--col {
            @include mq('<=sm') {
                display: flex;
                flex-direction: column;
            }
        }
    }

    // Facets
    & {
        .overlay-share--col {
            &.overlay-share--col__button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1 1 auto;
            }

            &.overlay-share--col__input {
                flex: 1 1 auto;
            }
        }
    }

    // States
}
