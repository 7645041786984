.overlay-add {
    // Vars
    $padding: 16px;

    // Support

    // Module
    & {
        & {
            @include grid--root((row: 'row', cols: ('col')));
            @include grid--space((row: 'row', cols: 'col', gutter: ($padding * 2, $padding)));
        }

        .overlay-add--form {
            @include scrollable-overlay-content;
        }

        .overlay-add--radio {
            margin-bottom: $padding;
        }

        .overlay-add--meta {
            margin-top: $padding;
            margin-bottom: $padding * 2;
        }

        .overlay-add--intro {
            margin-bottom: $padding * 2;
        }

        .overlay-add--data-picker {
            padding: {
                top: 40px;
            }
        }
    }

    // Facets
    & {
        .overlay-add--col {
            &.overlay-add--col__plz {
                width: 100%;

                @include mq('>sm') {
                    max-width: 124px;
                }
            }

            &.overlay-add--col__stretched {
                flex: 1 1 auto;
            }
        }
    }

    // States
}
