.address-list-item {
    // Vars
    $x-padding: 32px;

    // Support
    @mixin toggle-button-focus-state {
        color: $c--monochrome-white;
        box-shadow: 0 0 0 2px $c--brand-primary-dark inset;
        background: $c--brand-primary-dark;
    }

    @mixin hover-state {
        @include mq('>md') {
            border-color: $c--monochrome-gray;
            background: $c--monochrome-cardboard-gray;
        }

        .address-list-item--toggle {
            color: $c--brand-primary;
            box-shadow: 0 0 0 2px $c--brand-primary inset;
        }

        .address-list-item--button {
            opacity: 1;
        }
    }

    // Module
    & {
        & {
            display: flex;
            background: $c--monochrome-paper-white;
            border: 1px solid $c--monochrome-light-gray;
            padding: $x-padding / 2;
            border-radius: $sz--border-radius-base;
            transition: $trs--base background;
            cursor: pointer;

            @include grid--root((row: 'row', cols: ('col')));
            @include grid--space((row: 'row', cols: 'col', gutter: 16px, mq: '>md'));
            @include grid--space((row: 'row', cols: 'col', gutter: 12px, mq: '<=md'));

            @include mq('<=md') {
                padding: 0 12px;
            }

            @media print {
                border: none;
                padding: ($x-padding / 2) 0;
                background: transparent;
            }
        }

        .address-list-item--ripple {
            color: $c--brand-primary;
        }

        .address-list-item--row {
            align-items: center;
            flex-wrap: nowrap;
            flex: 1 1 100%;
        }

        .address-list-item--button {
            cursor: pointer;
            color: $c--brand-primary;
            padding: 8px;
            transition: $trs--base opacity;

            @include has-focus {
                color: $c--brand-primary-dark;
            }

            @include mq('<=md') {
                padding: 12px;
            }

            @include mq('>md') {
                @include supports-hover {
                    opacity: 0;
                }
            }

            @include mq('<=md') {
                flex: 1 1 100%;
                color: $c--monochrome-silver;

                ~ .address-list-item--button {
                    border-top: 1px solid $c--monochrome-light-gray;
                }
            }
        }

        .address-list-item--toggle {
            border-radius: 100%;
            cursor: pointer;
            color: $c--monochrome-silver;
            box-shadow: 0 0 0 2px $c--monochrome-silver inset;
            transition: transition-props($trs--base, color, box-shadow, background-color, opacity, transform);

            @media print {
                color: transparent;
                border: 1px solid $c--monochrome-silver;
                box-shadow: none;
            }
        }

        .address-list-item--button-content {
            display: block;
            max-width: 32px;
            max-height: 32px;
        }

        .address-list-item--title,
        .address-list-item--paragraph {
            hyphens: auto;
        }

        .address-list-item--title {
            color: $c--monochrome-black-gray;
        }

        .address-list-item--paragraph {
            margin-top: 4px;
            color: $c--monochrome-dark-gray;
        }
    }

    // Facets
    .address-list-item--col {
        &.address-list-item--col__edit-buttons,
        &.address-list-item--col__toggle {
            flex: 0 0 auto;
        }

        &.address-list-item--col__toggle {
            max-height: 32px;
        }

        &.address-list-item--col__text {
            flex: 1 1 auto;

            @include mq('<=md') {
                padding: {
                    top: 16px;
                    bottom: 16px;
                }
            }
        }

        &.address-list-item--col__edit-buttons {
            @include mq('>md') {
                margin: -8px;
            }

            @include mq('<=md') {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 100%;
                padding: 0;
                border-left: 1px solid $c--monochrome-light-gray;
                margin-right: -$x-padding / 4;
            }

            @media print {
                display: none;
            }
        }
    }

    // States

    & {
        // focus state
        & {
            @include has-focus {
                @include hover-state;
            }

            @include supports-hover {
                &:focus-within {
                    @include hover-state;
                }
            }
        }

        &.address-list-item__approved {
            .address-list-item--toggle {
                color: $c--monochrome-white;
                background: $c--brand-primary;
                box-shadow: 0 0 0 2px $c--brand-primary inset;

                @include has-focus {
                    @include toggle-button-focus-state;
                }

                @media print {
                    color: $c--label-text;
                    background: transparent;
                    box-shadow: none;
                }
            }

            .address-list-item--col__text {
                @media screen {
                    opacity: 0.25;
                }
            }

            .address-list-item--col__edit-buttons {
                visibility: hidden;
            }
        }

        &.address-list-item__deleted {
            .address-list-item--col__text {
                @media screen {
                    opacity: 0.25;
                }

                @media print {
                    text-decoration: line-through;
                }
            }
        }
    }
}
