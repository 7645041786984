/* stylelint-disable plugin/selector-bem-pattern, max-nesting-depth */
.datepicker {
    // Vars

    // Module
    & {
        .datepicker--wrap {
            position: relative;
        }

        .datepicker--label {
            padding-bottom: 16px;
        }

        .datepicker--icon {
            pointer-events: none;
            position: absolute;
            top: 50%;
            right: $sz--input-padding-x;
            transform: translate(0, -50%);

            color: $c--monochrome-silver;
        }

        .datepicker--hidden-input {
            pointer-events: none;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .datepicker--error {
            margin-top: 8px;
        }
    }

    // Facets

    // States
    & {
        &.datepicker__is-invalid {
            .datepicker--wrap {
                border-radius: $sz--border-radius-base;
                box-shadow: 0 0 0 1px $c--signal-error;
            }
        }
    }
}
