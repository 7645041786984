@mixin main-center-col {
    @include grid--span((ratio: 6 / 12, mq: '>lg'));
    @include grid--span((ratio: 10 / 12, mq: 'lg'));
    @include grid--span((ratio: 12 / 12, mq: '<=md'));

    @media print {
        flex: 1 1 100%;
        width: 100%;
    }
}
