/// Medium
///
@mixin btn--size-medium {
    padding: 13px 20px 11px;
    font-size: $fz--medium;
    line-height: $lh--small;
    min-width: 100px;
}

/// Small
///
@mixin btn--size-small {
    padding: 7px 16px 6px;
    font-size: $fz--small;
}

/// Medium bubble
///
@mixin btn--size-bubble-medium {
    padding: 8px;
    min-width: auto;
}

/// Small bubble
///
@mixin btn--size-bubble-small {
    padding: 4px;
}
