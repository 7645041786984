.page-not-found {
    // Vars
    // Support
    // Module
    & {
        display: flex;
        flex: 1 1 auto;
        align-content: center;
        justify-content: center;
        flex-flow: row wrap;
    }

    .page-not-found--row {
        justify-content: center;
    }

    .page-not-found--col {
        @include main-center-col;
    }

    .page-not-found--button {
        display: flex;
        justify-content: flex-end;
        padding-top: 50px;
    }

    .page-not-found--title {
        @include ff--base-bold;
        font-size: 5.4rem;
    }

    .page-not-found--copy.page-not-found--copy {
        line-height: $lh--x-small;
    }

    // Facets
    // States
}
